<script>
import TablePage from '../../../../../components/table_page';
// import request from '../../../../../utils/request';

import Form from './form.vue';

export default {
  name: 'table-component',
  extends: TablePage,
  components: {
    Form,
  },
  data() {
    return {
      requestUrl: '/sfa/sfaSignFormsController/exceptionReportDetail',
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  created() {
    this.getConfigList('sfaCenter_inspectionManage_inspectionPersonAreaConfig');
  },
};
</script>
