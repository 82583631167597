<template>
  <span>
    <el-select v-model="select.value" multiple placeholder="请选择" @focus="selectFocus">
      <el-option v-for="(item, index) in select.selected" :label="item.name" :value="item.orgCode" :key="index"></el-option>
    </el-select>

    <el-dialog title="请选择组织" :visible.sync="dialog.visible"
               @close="modalBtnClose" width="70%" destroy-on-close append-to-body>
      <el-form :inline="true">
        <el-form-item label="组织编码：">
          <el-input v-model="dialog.request.orgCode" clearable></el-input>
        </el-form-item>
        <el-form-item label="组织名称：">
          <el-input v-model="dialog.request.orgName" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="btnSearch">查询</el-button>
          <el-button type="primary" @click="btnReset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="dialog.result.data" @selection-change="tableSelectionChange">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="组织编码" prop="orgCode"></el-table-column>
        <el-table-column label="组织名称" prop="orgName"></el-table-column>
        <el-table-column label="组织类型" prop="orgType"></el-table-column>
      </el-table>
      <span slot="footer">
        <el-button @click="modalBtnClose">取消</el-button>
        <el-button type="primary" @click="modalBtnConfirm">确定</el-button>
      </span>
    </el-dialog>
  </span>
</template>

<script>
// import request from '../../../../../../utils/request';
import * as utils from '../../../../utils/index';

const requestDefault = {
  orgCode: '',
  orgName: '',
  pageable: { page: 1, size: 20 },
};
export default {
  props: {},
  data() {
    return {
      select: {
        value: [],
        selected: [],
      },
      dialog: {
        visible: false,
        selected: [],
        request: requestDefault,
        result: {
          data: [],
          total: 0,
        },
      },
    };
  },
  methods: {
    /** @desc 下拉卡聚焦 */
    selectFocus() {
      this.dialog.visible = true;
      this.getDialogTableList();
    },
    /** @desc 按钮搜索 */
    btnSearch() {
      this.getDialogTableList();
    },
    /** @desc 按钮重置 */
    btnReset() {
      this.dialog.request = requestDefault;
    },
    /** @desc 获取列表 */
    getDialogTableList() {
      try {
        this.dialog.result.data = [
          { orgname: '组织1', orgCode: '组织1' },
          { orgname: '组织2', orgCode: '组织2' },
          { orgname: '组织3', orgCode: '组织3' },
        ];
        this.dialog.result.total = 3;

        // request.post('/www.baidu.com', this.dialog.request).then((res) => {
        //   this.dialog.result.data = res.content;
        //   this.dialog.result.total = res.total;
        // }).catch(() => { });
      } catch (error) {
        console.log(error);
      }
    },
    /** @desc 获取列表 */
    tableSelectionChange(value) {
      this.dialog.result.selected = value;
    },
    /** @desc 弹窗关闭按钮 */
    modalBtnClose() {
      this.btnReset();
      this.dialog.visible = false;
    },
    /** @desc 弹窗确认按钮 */
    modalBtnConfirm() {
      const merge = utils.duplicate(this.select.selected.concat(this.dialog.result.selected), 'orgCode');
      this.select.selected = merge;
      this.select.value = merge.map((v) => v.orgCode);
      this.dialog.visible = false;
    },

  },
};
</script>
