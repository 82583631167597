var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "el-select",
        {
          attrs: { multiple: "", placeholder: "请选择" },
          on: { focus: _vm.selectFocus },
          model: {
            value: _vm.select.value,
            callback: function ($$v) {
              _vm.$set(_vm.select, "value", $$v)
            },
            expression: "select.value",
          },
        },
        _vm._l(_vm.select.selected, function (item, index) {
          return _c("el-option", {
            key: index,
            attrs: { label: item.name, value: item.orgCode },
          })
        }),
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "请选择组织",
            visible: _vm.dialog.visible,
            width: "70%",
            "destroy-on-close": "",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dialog, "visible", $event)
            },
            close: _vm.modalBtnClose,
          },
        },
        [
          _c(
            "el-form",
            { attrs: { inline: true } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "组织编码：" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.dialog.request.orgCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.dialog.request, "orgCode", $$v)
                      },
                      expression: "dialog.request.orgCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "组织名称：" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.dialog.request.orgName,
                      callback: function ($$v) {
                        _vm.$set(_vm.dialog.request, "orgName", $$v)
                      },
                      expression: "dialog.request.orgName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.btnSearch },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.btnReset } },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              attrs: { data: _vm.dialog.result.data },
              on: { "selection-change": _vm.tableSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _c("el-table-column", {
                attrs: { label: "组织编码", prop: "orgCode" },
              }),
              _c("el-table-column", {
                attrs: { label: "组织名称", prop: "orgName" },
              }),
              _c("el-table-column", {
                attrs: { label: "组织类型", prop: "orgType" },
              }),
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.modalBtnClose } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.modalBtnConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }