<script>
import Form, { formCreate } from '../../../../../components/form';
import SelectOrgModal from './components/select_org_modal.vue';

formCreate.component('selectOrgModal', SelectOrgModal);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  methods: {
    async createRule() {
      const resultRules = await this.getBeforFormRule('sfaCenter_inspectionManage_inspectionPersonAreaConfig_add');
      this.rule = resultRules.concat([{ type: 'selectOrgModal', title: '稽查区域', field: 'ceshi' }]);
      this.reload(this.rule);
    },
  },
  created() {
    this.createRule();
  },
};
</script>
